import React, { Component } from "react";
/* css */
import "../styles/flipbookMv.css";
import "../styles/styleVwObraCivil.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight} from '@fortawesome/free-solid-svg-icons';

class DisplayMv  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);
      }

    flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo = document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');

        page.classList.add('movFlipEfectNext');
        
        if (!elemenVideo){
            /* null */
        }else{
            let vpause= document.querySelectorAll('video')[indexVideo];
            vpause.pause();
        }

        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'), page.style.transform = 'translateX(100%)' )), 800);
        }else{
            setTimeout(() => ((page.style.zIndex = index, page.style.transform = 'translateX(100%)')), 800);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                setTimeout(()=> (e.classList.add('eVisibility')), 800); 
            }
        });


       /*  let tamaño = window.screen.width;
        let tamaño2 = window.screen.height;
        alert("screen" + tamaño);
        alert(tamaño2); */
 
    }

    flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');
        
        page.classList.remove('movFlipEfectNext');
        page.style.transform ='translateX(0%)';

        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 300);
        }else{
            setTimeout(() => (page.style.zIndex = index), 300);
        }
        
        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                /* e.classList.remove('eVisibility'); */ 
                setTimeout(()=> (e.classList.remove('eVisibility')), 300); 
            }
        });
    }

    render(){
        return(
            <div className="flip-container">
                {/* paper 1 */}
                <div id="movPage1" className="page">
                    <div className="front">
                        <div className="front__content background-portada-1">
                            <div className="goBack"></div>
                            <div className="front__content-text">
                                <div className="front__portada">
                                    <div style={{display:'flex', justifyContent:'end', alignItems:'end', width:'100%', height:'62%', color:'#2b1385ff'}}>
                                        <h3>OBRA CIVIL</h3>
                                    </div>
                                </div>
                            </div>
                            <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                <a style={{opacity:'0.5', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage1', 0, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 2 */}
                <div id="movPage2" className="page">
                    <div className="front">
                        <div className="front__content background-page1-1">
                            <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage1', 29, '', '', '', "p1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{height:'2em', display:'flex', alignItems:'center', justifyContent:'end'}}><h4 style={{paddingRight:'10px', color:'#2b1385ff'}}>Obra Civil</h4></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <p style={{fontFamily:'Montserrat-SemiBold', textAlign:'right', width:'70%'}}>
                                            Cambio de Cubiertas con instalación de correas para refuerzos estructurales.
                                        </p>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma1.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma2.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma3.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma4.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma5.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma6.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage2', 1, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 3 */}
                <div id="movPage3" className="page">
                    <div className="front">
                        <div className="front__content background-page1-1">
                            <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage2', 28, '', '', '', "p2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{height:'2em', display:'flex', alignItems:'center', justifyContent:'end'}}><h4 style={{paddingRight:'10px', color:'#2b1385ff'}}>Obra Civil</h4></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <p style={{fontFamily:'Montserrat-SemiBold', textAlign:'right', width:'70%'}}>
                                            Cambio de película de seguridad.
                                        </p>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma7.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma8.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma9.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma10.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma11.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma12.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div data-active="p3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage3', 2, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                   
                </div>
                {/* paper 4 */}
                <div id="movPage4" className="page">
                    <div className="front">
                        <div className="front__content background-page1-1">
                            <div data-active="p4" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage3', 27, '', '', '', "p3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{height:'2em', display:'flex', alignItems:'center', justifyContent:'end'}}><h4 style={{paddingRight:'10px', color:'#2b1385ff'}}>Obra Civil</h4></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <p style={{fontFamily:'Montserrat-SemiBold', textAlign:'right', width:'70%'}}>
                                            Cambio y arreglo de antepecho fachada.
                                        </p>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma13.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma14.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma15.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma16.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma17.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma18.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div data-active="p4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage4', 3, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> 
                {/* paper 5 */}
                <div id="movPage5" className="page">
                    <div className="front">
                        <div className="front__content background-page1-1">
                            <div data-active="p5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage4', 26, '', '', '', "p4")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{height:'2em', display:'flex', alignItems:'center', justifyContent:'end'}}><h4 style={{paddingRight:'10px', color:'#2b1385ff'}}>Obra Civil</h4></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <p style={{fontFamily:'Montserrat-SemiBold', textAlign:'right', width:'70%'}}>
                                            Instalación de punto hidráulico e instalación de sifón y poceta.
                                        </p>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma19.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma20.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma21.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma22.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma23.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma24.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                            <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage5', 4, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                {/* paper 6 */}
                <div id="movPage6" className="page">
                    <div className="front">
                        <div className="front__content background-page1-1">
                            <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage5', 25, '', '', '', "p5")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{height:'2em', display:'flex', alignItems:'center', justifyContent:'end'}}><h4 style={{paddingRight:'10px', color:'#2b1385ff'}}>Obra Civil</h4></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <p style={{fontFamily:'Montserrat-SemiBold', textAlign:'right', width:'70%'}}>
                                            Cambio, retiro e instalación de muebles baños.
                                        </p>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma25.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma26.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma27.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma28.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma29.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma30.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                              </div>
                            </div>
                            <div data-active="1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage6', 5, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                 
                </div>
                {/* paper 7 */}
                <div id="movPage7" className="page">
                    <div className="front">
                        <div className="front__content background-page1-1">
                            <div data-active="2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage6', 24, '', '', '', "1")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'90%'}}>
                                    <div style={{padding:'0px 0px 10px 0px'}}>
                                        <div style={{height:'2em', display:'flex', alignItems:'center', justifyContent:'end'}}><h4 style={{paddingRight:'10px', color:'#2b1385ff'}}>Obra Civil</h4></div>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        <p style={{fontFamily:'Montserrat-SemiBold', textAlign:'right', width:'70%'}}>
                                            Render obra civil local comercial , luces , pisos , drywall , avisos iluminados , muebles en mdf , metalmecanica y acrílicos.
                                        </p>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="2" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma31.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma32.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma33.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="2" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma34.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma35.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/gamma36.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>          
                                </div>
                            </div>
                            <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage7', 6, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* paper 8 */}
                <div id="movPage8" className="page">
                    <div className="front" >
                        <div className="front__content background-page2-1">
                            <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('movPage7', 23, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            <div className="front__content-text">
                                <div style={{height:'97%'}}>
                                    <div className="front__portada">
                                    <img className="front__logo" style={{maxWidth:"60%", height:'auto'}}  src="./img/logo.png" alt="logo" />
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', paddingTop:'20px'}}>
                                        {/* Fin */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div data-active="3" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}}>
                                {/* <a style={{opacity:'0.9', backgroundColor:'#2b1385ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('movPage8', 7, '', '', '', "3")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        );
    }
}

export default DisplayMv;