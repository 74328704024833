import React, { Component } from "react";
/* css */
import "../styles/flipbookDt.css";
import "../styles/styleVw.css";
/* librerias */
import M from 'materialize-css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight, faPrint } from '@fortawesome/free-solid-svg-icons';

const userMac= navigator.platform.toUpperCase().indexOf('MAC') >=0;
const userWin= navigator.platform.toUpperCase().indexOf('WIN') >=0;


class DisplayDt  extends Component{

    componentDidMount(){
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox;
        instances.init(elems);
      }

    flipPageNext(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');
        
        page.classList.add('flipEfectNext');

        if (!elemenVideo){
            /* null */
        }else{
            let vpause= document.querySelectorAll('video')[indexVideo];
            vpause.pause();
        }
        
        if (view === 1){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.add('eVisibility'))), 800);
        }else{
            setTimeout(() => ((page.style.zIndex = index)), 800);
        }

        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                setTimeout(()=> (e.classList.add('eVisibility')), 800); 
            }
        });
        
        if(userMac || userWin){
            let contened = document.getElementsByClassName('flip-container')[0];
            contened.style.transform = 'translateX(50%)';
        }
    }

    flipPageBack(elemt, index, view, elemenVideo, indexVideo, elemenImg){
        let page = document.getElementById(elemt);
        let eVideo= document.getElementById(elemenVideo);
        let eImg = document.querySelectorAll('div[data-active]');
        let evaUser = (userMac || userWin) && index === 12000;

        page.classList.remove('flipEfectNext');
       
        if (view === 0){
            setTimeout(()=>((page.style.zIndex = index, eVideo.classList.remove('eVisibility'))), 300);
        }else{
            setTimeout(() => (page.style.zIndex = index), 300);
        }
        
        eImg.forEach(function(e){
            if (e.dataset.active === elemenImg){
                /* e.classList.remove('eVisibility'); */ 
                setTimeout(()=> (e.classList.remove('eVisibility')), 300); 
            }
        });
        
        if(evaUser){
            let contened = document.getElementsByClassName('flip-container')[0];
            contened.style.transform = 'translateX(0%)';
        }
    }

    paperNone(){
                
        let paper4 = document.getElementById('page4');
        /* let paper5 = document.getElementById('#page5');
        let paper6 = document.getElementById('#page6');
        let paper7 = document.getElementById('#page7');
        let paper8 = document.getElementById('#page8');
        let paper9 = document.getElementById('#page9');
        let paper10 = document.getElementById('#page10');
        let paper11 = document.getElementById('#page11');
        let paper12 = document.getElementById('#page12'); */

        paper4.classList.add('paperNone');
        /* paper5.classList.add('paperNone');
        paper6.classList.add('paperNone');
        paper7.classList.add('paperNone');
        paper8.classList.add('paperNone');
        paper9.classList.add('paperNone');
        paper10.classList.add('paperNone');
        paper11.classList.add('paperNone');
        paper12.classList.add('paperNone'); */

    }
    
    render(){
        return(
            <div id="page-flip">
                <div className="flip-container">
                    {/* paper 1 */}
                    <div id="page1" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-portada">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    <div className="front__portada">
                                        <img className="front__logo" style={{maxWidth:"65%", height:'auto'}}  src="./img/logo.png" alt="logo" />
                                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', paddingTop:'20px'}}>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%'}}>
                                                <a style={{ display:'flex', justifyContent:'center' }} href="/#" >
                                                    <img className="" style={{width:'60%', cursor:'pointer'}} src="./img/38.png" alt="banderas" />
                                                </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'50%'}}>
                                                <a style={{ display:'flex', justifyContent:'center' }} href="/#" >
                                                    <img className="" style={{width:'60%', cursor:'pointer'}} src="./img/98.png" alt="banderas" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page1', 1)}></div> */}
                                <div data-active="p1" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page1', 0, '', '', '', "p1")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page1', 0, '', '', '', "p1")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page1">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page1', 10)}></div> */}
                                <div data-active="p2" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page1', 12000, '', '', '', "p1")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page1', 12000, '', '', '', "p1")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text">
                                    <div style={{height:'80%', display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                                        <h5 style={{color:'#0e95dd'}} translate="no">Color y vida a tus ideas</h5>
                                        <h4 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">IMPRESIONES</h4>
                                        <h6 style={{paddingBottom:'30px', color:'#3d348a'}} translate="no">DE ALTA RESOLUCIÓN</h6>
                                    </div>
                                    <div style={{height:'20%', display:'flex', alignItems:'center'}}>
                                        <p style={{color:'#3d348a', wordSpacing:'0.1em'}} translate="no">
                                            Gamma Comunicación Gráfica S.A.S cuenta con amplia experiencia produciendo piezas publicitarias con alta resolución, 
                                            empleando materiales que cumplen los más altos estándares internacionales.
                                        </p>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 2 */}
                    <div id="page2" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page2">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    <div style={{width:'100%', height:'60%', display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                                        <h4 style={{margin:0, paddingBottom:'5px', color:'#3d348a'}} translate="no">¿Quiénes</h4>
                                        <h6 style={{color:'#3d348a', paddingBottom:'15px'}} translate="no">Somos?</h6>    
                                    </div>
                                    <div style={{width:'100%', height:'40%'}}>
                                        <p style={{color:'#3d348a', width:'100%', textAlign:'justify', paddingBottom:'5px', wordSpacing:'0.1em'}} translate="no">
                                            Gamma Comunicación Gráfica S.A.S es una empresa de servicios comprometida con el medio  ambiente, enfocada en el área de comunicación y marketing.
                                        </p>
                                        <p style={{color:'#3d348a', width:'100%', textAlign:'justify', wordSpacing:'0.1em'}} translate="no">
                                            Nuestra empresa es especialista en desarrollo de campañas publicitarias, empleando materiales que cumplen los más altos extandares de calidad, tales como: Cartón, 
                                            Acrílico, Banner, MDF, Foamboard, Vinilos, Lamina Metálica, Floorgraphics, Duratrans, entre otros.
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page2', 2)}></div> */}
                                <div data-active="p2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page2', 1, '', '', '', "p2")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page2', 1, '', '', '', "p2")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page3">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page2', 9)}></div> */}
                                <div data-active="p3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page2', 11000, '', '', '', "p2")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page2', 11000, '', '', '', "p2")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text">
                                    <div style={{width:'100%', height:'20%', display:'flex', flexDirection:'column', justifyContent:'flex-end'}}>
                                        <h4 style={{margin:0, paddingBottom:'15px', color:'#3d348a'}} translate="no">Misión</h4>   
                                    </div>
                                    <div style={{width:'100%', height:'30%'}}>
                                        <p style={{color:'#3d348a', width:'70%', textAlign:'left', paddingBottom:'5px', wordSpacing:'0.1em'}} translate="no">
                                            Nuestra misión es generar un alto impacto y gran reconocimiento de la marca de nuestros clientes a través del trabajo en equipo, talento humano y proveedores. Contando con tecnología de punta, creatividad y cumplimiento en todos nuestros productos y servicios. 
                                        </p>
                                    </div>
                                    <div style={{width:'100%', height:'10%', display:'flex', flexDirection:'column', justifyContent:'flex-end', alignItems:'flex-end'}}>
                                        <h4 style={{margin:0, paddingBottom:'5px', color:'#3d348a'}} translate="no">Visión</h4>   
                                    </div>
                                    <div style={{width:'100%', height:'40%', display:'flex', justifyContent:'flex-end'}}>
                                        <p style={{color:'#3d348a', width:'68%', textAlign:'right', paddingBottom:'5px', wordSpacing:'0.1em'}} translate="no">
                                            Para el 2025, ser la mejor empresa latinoamericana del sector publicitario que otorgue servicios de reconocimiento de marca, de excelente calidad y con un tiempo de entrega óptimo. 
                                            Con una organización siempre comprometida con la sociedad y el medio ambiente, exaltando el talento humano de nuestros colaboradores, clientes y proveedores.
                                        </p>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 3 */}
                    <div id="page3" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page4">
                                <div className="goBack"></div>
                                <div className="front__content-text">
                                    <div style={{height:'30%', width:'100%', marginBottom:'5px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">IMPRESIÓN UV</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Sobre Rígidos 1.5m x <span>&#8734;</span></h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <ul>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Materiales rígidos planos.</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Materiales rígidos cilíndricos.</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Materiales Flexibles.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div  style={{height:'35%', width:'100%'}}>
                                        <video id="video0" className="cVideo" controls preload="none" poster="./img/poster/1.png">
                                            <source src="./media/199.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}> 
                                                <img className="materialboxed eImg" src="./img/1.png" alt="" />
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/2.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="p5" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/3.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page3', 3, 1, 'video1')}></div> */}
                                <div data-active="p5" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page3', 2, 1, 'video0', 0, "p5")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page3', 2, 1, 'video0', '0', "p5")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page5">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page3', 8, 0, 'video1')}></div> */}
                                <div data-active="1" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page3', 10000, 0, 'video0', 0, "p5")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page3', 10000, 0, 'video0', 0, "p5")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text">
                                    <div style={{height:'30%', width:'100%', marginBottom:'5px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">IMPRESIÓN</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Gran Formato - Gigantografía</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <ul>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Lona (Banner).</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Microperforado.</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Vinilo conformable.</p>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Floor Graphic</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <p>(Vinilo alto tráfico)</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div  style={{height:'35%', width:'100%'}}>
                                        <video id="video1" className="cVideo" controls preload="none" poster="./img/poster/3.png">
                                            <source src="./media/423.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/11.png" alt="" onClick={(e) => this.paperNone(e)}/>
                                            </div>
                                        </div>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/13.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="1" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/6.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 4 */}
                    <div id="page4" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page6">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginBottom:'5px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">DECORACIÓN</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Comercial</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <ul>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Avisos Luminosos.</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Acrílicos Termodoblados.</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Decoración de Interiores.</p>
                                                </li>
                                                <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                    <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Microperforados.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="2" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'58%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/10.png" alt=""/>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Avisos Luminosos</p>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'58%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/8.png" alt=""/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Termodoblados</p>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/9.png" alt=""/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Decoración de Interiores</p>
                                            </div>
                                        </div>
                                        <div data-active="2" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/70.png" alt=""/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Microperforado</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page4', 4)}></div> */}
                                <div data-active="2" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page4', 3, '', '', '', "2")}>
                                <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page4', 3, '', '', '', "2")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page7">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page4', 7)}></div> */}
                                <div data-active="3" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page4', 9000, '', '', '', "2")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page4', 9000, '', '', '', "2")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginBottom:'5px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">VITRINISMO</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">+ Decoración Comercial</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'65%', textAlign:'left'}} translate="no">
                                                La finalidad del vitrinismo es facilitar la compra de los productos a través de la organización lógica, creativa y estratégica 
                                                de los mismos para que generen una experiencia agradable a los clientes.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="3" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'58%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/71.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="3" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'58%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/72.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-active="3" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/73.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-active="3" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/16.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 5 */}
                    <div id="page5" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page8">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginTop:'5px', marginBottom:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">VINILO</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Frosted</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'100%', textAlign:'left', wordSpacing:'0.1em'}} translate="no">
                                                Es un material que por su versatilidad puede ser utilizado en la decoración de oficinas, empresas, locales comerciales como restaurantes o tiendas de ropa.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'35%', width:'100%'}}>
                                        <video id="video2" className="cVideo" controls preload="none" poster="./img/poster/6.png">
                                            <source src="./media/352.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="4" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/74.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="4" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/75.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="4" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/76.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="4" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page5', 4, 1, 'video2', 2, "4")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page5', 4, 1, 'video2', 2, "4")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page9">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                                <div data-active="5" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page5', 8000, 0, 'video2', 2, "4")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page5', 8000, 0, 'video2', 2, "4")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginBottom:'5px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">DECORACIÓN</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Vehicular</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'65%', textAlign:'left'}} translate="no">
                                                La decoración vehicular es la mejor opción para dar a conocer tu marca a nuevos clientes dandole un toque personal y llamativo.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="5" style={{position:'relative', width:'50%', height:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'50%', height:'60%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div>
                                                <img className="materialboxed eImg" src="./img/14.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="5" style={{position:'relative', width:'50%', height:'33%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%',direction:'rtl', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'50%', height:'60%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                    <img className="materialboxed eImg" src="./img/15.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-active="5" style={{position:'relative', width:'30%', height:'20%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'100%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/40.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="5" style={{position:'relative', width:'100%', height:'33%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'50%', height:'95%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/39.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 6 */}
                    <div id="page6" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page10">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginTop:'5px', marginBottom:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px',color:'#3d348a'}} translate="no">RETAIL</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Comercial</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'65%', textAlign:'left', wordSpacing:'0.1em'}} translate="no">
                                                Tienen la función de contener el producto y exhibirlo de forma atractiva y añadiéndole los valores de la marca.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="6" style={{position:'relative', width:'50%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'30%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'70%'}}>
                                                <img className="materialboxed eImg" src="./img/17.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="6" style={{position:'relative', width:'25%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'70%'}}>
                                                    <img className="materialboxed eImg" src="./img/18.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-active="6" style={{position:'relative', width:'25%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'15%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'40%'}}>
                                                <img className="materialboxed eImg" src="./img/20.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="6" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page6', 5, '', '', '', "6")}>
                                <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page6', 5, '', '', '', "6")}>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                </a>
                            </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page11">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                                <div data-active="7" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page6', 7000, '', '', '', "6")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page6', 7000, '', '', '', "6")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'35%', width:'100%', marginBottom:'5px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">CORTE Y GRABADO LÁSER</h5>
                                        {/* <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no"></h6> */}
                                        <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'100%', textAlign:'justify', paddingBottom:'10px'}} translate="no">
                                                Contamos con maquinaria de alta precisión para realizar cortes y grados, otorgando terminados de alta calidad y precisión sobre materiales como: 
                                            </p>
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                                <ul>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Acrílico.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>MDF.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Maderas.</p>
                                                    </li>
                                                </ul>
                                                <ul style={{paddingLeft:'20px', paddingRight:'20px'}}>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Cuero.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Cerámica.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Poliestireno.</p>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Cartón.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Vidrio.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}} translate="no">
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p>Telas.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div  style={{height:'35%', width:'100%'}}>
                                        <video id="video3" className="cVideo" controls preload="none" poster="./img/poster/4.png">
                                            <source src="./media/420.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div  style={{height:'30%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="7" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/22.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="7" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/23.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="7" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/24.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 7 */}
                    <div id="page7" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page12">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginTop:'5px', marginBottom:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">RISTRAS</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Tiras de impulso de Marca</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'65%', textAlign:'left'}} translate="no">
                                                Exhibe tus productos de forma atractiva, aprovechando todos los espacios disponibles.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="8" style={{position:'relative', width:'50%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'30%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'45%'}}>
                                                <img className="materialboxed eImg" src="./img/25.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="8" style={{position:'relative', width:'25%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'60%'}}>
                                                    <img className="materialboxed eImg" src="./img/26.png" alt=""/>
                                                </div>
                                            </div>
                                        </div> 
                                        <div data-active="8" style={{position:'relative', width:'25%', height:'130%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'13%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'60%'}}>
                                                    <img className="materialboxed eImg" src="./img/27.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="8" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page7', 6, '', '', '', "8")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page7', 6, '', '', '', "8")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page13">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                                <div data-active="9" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page7', 6000, '', '', '', "8")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page7', 6000, '', '', '', "8")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginBottom:'10px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">CNC</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Router</h6>
                                        <div style={{display:'flex', flexDirection:'column', color:'#3d348a'}}>
                                            <p style={{width:'100%', paddingBottom:'10px'}} translate="no">
                                                Corte computarizado de alta precisión, sobre materiales rigidos como: 
                                            </p>
                                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center', paddingTop:'5px', color:'#3d348a', width:'100%'}}>
                                                <ul>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p translate="no">Poliestireno.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p translate="no">MDF.</p>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p translate="no">PVC.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p translate="no">Acrílico.</p>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <span style={{color:'#e6007e', paddingRight:'8px'}}><FontAwesomeIcon icon={faPrint}/></span><p translate="no">Alucobond.</p>
                                                    </li>
                                                    <li style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                        <p translate="no">entre otros.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div  style={{height:'35%', width:'100%'}}>
                                        <video id="video4" className="cVideo" controls preload="none" poster="./img/poster/5.png">
                                            <source src="./media/424.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div  style={{height:'35%', width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <div data-active="9" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/28.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="9" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/29.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="9" style={{position:'relative', width:'33.33%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'80%', height:'46%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{}}>
                                                <img className="materialboxed eImg" src="./img/30.png" alt=""/>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 8 */}
                    <div id="page8" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page14">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginTop:'5px', marginBottom:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">POP</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Bandejas</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'50%', wordSpacing:'0.1em', textAlign:'left'}} translate="no">
                                                Elementos de exhibición en materiales variados para destacar tu marca.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="10" style={{position:'relative', width:'40%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'60%', height:'80%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/31.png" alt=""/>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Exibición</p> */}
                                            </div>
                                        </div>
                                        <div data-active="10" style={{position:'relative', width:'40%', height:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'48%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/93.png" alt=""/>
                                                </div>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Balconera</p> */}
                                            </div>
                                        </div> 
                                        <div data-active="10" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/92.png" alt=""/>
                                                </div>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Bandeja</p> */}
                                            </div>
                                        </div>
                                        <div data-active="10" style={{position:'relative', width:'35%', height:'65%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'35%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/37.png" alt="" style={{width:'110%'}}/>
                                                </div>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Colgantes</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="10" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page8', 7, '', '', '', "10")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page8', 7, '', '', '', "10")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page15">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                                <div data-active="11" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page8', 5000, '', '', '', "10")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse waves-effect" href="/#" onClick={(e) => this.flipPageBack('page8', 5000, '', '', '', "10")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginTop:'5px', marginBottom:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">POP</h5>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Termoformados</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'60%', wordSpacing:'0.1em', textAlign:'left'}} translate="no">
                                                Fabricamos piezas plásticas grandes y pequeñas, con sistema de termoformado.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="11" style={{position:'relative', width:'40%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'60%', height:'80%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/91.png" alt=""/>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Exibición</p> */}
                                            </div>
                                        </div>
                                        <div data-active="11" style={{position:'relative', width:'40%', height:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'48%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/32.png" alt="" style={{width:'80%'}}/>
                                                </div>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Balconera</p> */}
                                            </div>
                                        </div> 
                                        <div data-active="11" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/33.png" alt=""/>
                                                </div>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Bandeja</p> */}
                                            </div>
                                        </div>
                                        <div data-active="11" style={{position:'relative', width:'35%', height:'65%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'35%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/94.png" alt="" style={{width:'80%'}}/>
                                                </div>
                                                {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Colgantes</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 9 */}
                    <div id="page9" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page16">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'25%', width:'100%', marginBottom:'10px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">POP</h5>
                                        <h6 style={{paddingBottom:'5px', color:'#3d348a'}} translate="no">Puntas de Góndola e</h6>
                                        <h6 style={{paddingBottom:'10px', color:'#3d348a'}} translate="no">Islas de Exhibición</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', paddingTop:'5px', color:'#3d348a'}}>
                                           {/*  <p style={{width:'70%'}} translate="no">
                                                Desarrollamos proyectos completos asesorando en cada proceso a nuestros clientes.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div  style={{height:'75%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div style={{height:'auto', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                            <div data-active="12" style={{position:'relative', width:'35%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'100%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                    <img className="materialboxed eImg" src="./img/34.png" alt=""/>
                                                </div>
                                            </div>
                                            <div data-active="12" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                    <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                        <a style={{width:'70%', height:'100%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                        <img className="materialboxed eImg" src="./img/35.png" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Puntas de Góndola</p>
                                        </div>
                                        <div data-active="12" style={{position:'relative', width:'45%', height:'25%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/36.png" alt="" style={{width:'125%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Isla de Exhibición</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="12" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page9', 8, '', '', '', "12")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page9', 8, '', '', '', "12")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page17">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                                <div data-active="13" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page9', 4000, '', '', '', "12")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageBack('page9', 4000, '', '', '', "12")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'15%', width:'100%', marginBottom:'10px', marginTop:'5px'}}>
                                        <h5 style={{paddingBottom:'5px', paddingTop:'10px', color:'#3d348a'}} translate="no">Mega</h5>
                                        <h6 style={{paddingBottom:'5px', color:'#3d348a'}} translate="no">Exhibiciones</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', paddingTop:'5px', color:'#3d348a'}}>
                                           {/*  <p style={{width:'70%'}} translate="no">
                                                Desarrollamos proyectos completos asesorando en cada proceso a nuestros clientes.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div  style={{height:'85%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div style={{height:'auto', width:'100%', display:"flex", justifyContent:'space-between', flexWrap:'wrap'}}>
                                            <div data-active="13" style={{position:'relative', width:'40%', height:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'60%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                    <img className="materialboxed eImg" src="./img/77.png" alt="" style={{width:'140%'}}/>
                                                </div>
                                            </div>
                                            <div data-active="13" style={{position:'relative', width:'40%', height:'90%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                    <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                        <a style={{width:'70%', height:'70%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                        <img className="materialboxed eImg" src="./img/95.png" alt="" style={{width:'120%'}}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Puntas de Góndola</p> */}
                                        </div>
                                        <div data-active="13" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'80%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/97.png" alt="" style={{width:'140%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">SHOWROOM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 10 */}
                    <div id="page10" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page18">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'30%', width:'100%', marginBottom:'10px'}}>
                                        <h4 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">AVISOS</h4>
                                        <h6 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">Neón</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'50%', textAlign:'left'}} translate="no">
                                                Desarrollamos proyectos en luces de neón, diseños atractivos para destacar tu marca.
                                            </p>
                                        </div>
                                    </div>
                                    <div  style={{height:'70%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="14" style={{position:'relative', width:'40%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'60%', height:'70%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/41.png" alt=""/>
                                            </div>
                                        </div>
                                        <div data-active="14" style={{position:'relative', width:'40%', height:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'48%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/42.png" alt=""/>
                                                </div>
                                            </div>
                                        </div> 
                                        <div data-active="14" style={{position:'relative', width:'45%', height:'15%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'55%', height:'100%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/44.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-active="14" style={{position:'relative', width:'45%', height:'50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'60%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/43.png" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="14" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page10', 9, '', '', '', "14")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page10', 9, '', '', '', "14")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page19">
                               {/*  <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                               <div data-active="15" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page10', 3000, '', '', '', "14")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageBack('page10', 3000, '', '', '', "14")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'15%', width:'100%', marginBottom:'10px'}}>
                                        <h4 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">SISTEMAS DE</h4>
                                        <h6 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">Exhibición Portable</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            {/* <p style={{width:'50%', textAlign:'left'}} translate="no">
                                                Desarrollamos proyectos en luces de neón, diseños atractivos para destacar tu marca.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div  style={{height:'85%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="15" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'50%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/78.png" alt=""/>
                                            </div>
                                            <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Backing</p>
                                        </div>
                                        <div data-active="15" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/79.png" alt=""/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Arcos</p>
                                            </div>
                                        </div>
                                        <div data-active="15" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'ltr', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'70%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/81.png" alt=""/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Sky Line</p>
                                            </div>
                                        </div>
                                        <div data-active="15" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'75%', height:'70%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/83.png" alt="" style={{width:'50%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Rollup</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 11 */}
                    <div id="page11" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page20">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'15%', width:'100%', marginBottom:'10px'}}>
                                        <h4 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">SISTEMAS DE</h4>
                                        <h6 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">Exhibición Portable</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            {/* <p style={{width:'50%', textAlign:'left'}} translate="no">
                                                Desarrollamos proyectos en luces de neón, diseños atractivos para destacar tu marca.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div  style={{height:'85%', width:'100%', display:"flex", justifyContent:'center', flexWrap:'wrap'}}>
                                        <div data-active="16" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                <a style={{width:'70%', height:'50%', opacity:'0.1', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%', flexDirection:'column'}}>
                                                <img className="materialboxed eImg" src="./img/80.png" alt=""  style={{width:'50%'}}/>
                                            </div>
                                            <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Rompetráfico</p>
                                        </div>
                                        <div data-active="16" style={{position:'relative', width:'40%', height:'40%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'70%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/82.png" alt=""  style={{width:'50%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Fly Banner</p>
                                            </div>
                                        </div>
                                        <div data-active="16" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', direction:'rtl' ,flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'50%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90%'}}>
                                                    <img className="materialboxed eImg" src="./img/84.png" alt=""  style={{width:'50%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Pendón Araña</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div> */}
                                <div data-active="16" className="next" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageNext('page11', 10, '', '', '', "16")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageNext('page11', 10, '', '', '', "16")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* back */}
                        <div className="back">
                            <div className="back__content background-page21">
                                {/* <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div> */}
                                <div data-active="17" className="goBack" style={{display:'flex', justifyContent:"center", alignItems:'center'}} onClick={(e) => this.flipPageBack('page11', 2000, '', '', '', "16")}>
                                    <a style={{opacity:'0.5', backgroundColor:'#009fe3ff', width:'95%', height:'5%', display:'flex', justifyContent:"center", alignItems:'center'}} className="btn-floating pulse" href="/#" onClick={(e) => this.flipPageBack('page11', 2000, '', '', '', "16")}>
                                        <FontAwesomeIcon icon={faArrowAltCircleLeft} style={{fontSize:'200%', color:'white', cursor:'pointer'}}/>
                                    </a>
                                </div>
                                <div className="front__content-text" >
                                    <div style={{height:'15%', width:'100%', marginBottom:'10px'}}>
                                        <h4 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">SEÑALIZACIÓN</h4>
                                        <h6 style={{marginBottom:'5px', color:'#3d348a'}} translate="no"> y Demarcación Industrial</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            {/* <p style={{width:'50%', textAlign:'left'}} translate="no">
                                                Desarrollamos proyectos en luces de neón, diseños atractivos para destacar tu marca.
                                            </p> */}
                                        </div>
                                    </div>
                                    <div  style={{height:'85%', width:'100%', display:"flex", justifyContent:'space-between', flexWrap:'wrap'}}>
                                        <div data-active="17" style={{position:'relative', width:'40%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'55%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/85.png" alt="" style={{width:'80%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Evacuación</p>
                                            </div>
                                        </div>
                                        <div data-active="17" style={{position:'relative', width:'40%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'55%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/86.png" alt="" style={{width:'60%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Fotoluminicentes</p>
                                            </div>
                                        </div>
                                        <div data-active="17" style={{position:'relative', width:'45%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'55%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/87.png" alt="" style={{width:'60%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Bioseguridad</p>
                                            </div>
                                        </div>
                                        <div data-active="17" style={{position:'relative', width:'45%', height:'30%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', direction:'rtl'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'55%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/88.png" alt="" style={{width:'60%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Informativas</p>
                                            </div>
                                        </div>
                                        <div data-active="17" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'55%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/89.png" alt="" style={{width:'80%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Advertencia</p>
                                            </div>
                                        </div>
                                        <div data-active="17" style={{position:'relative', width:'45%', height:'35%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column' }}>
                                            <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', direction:'rtl'}}>
                                                <div style={{zIndex:0, display:'flex', justifyContent:'center', alignItems:'center', position:'absolute', top:'0px', left:'0px', width:'100%', height:'100%'}}>
                                                    <a style={{width:'60%', height:'55%', opacity:'0.15', backgroundColor:'#3d348a'}} className="btn-floating pulse" href="/#"> </a>
                                                </div>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    <img className="materialboxed eImg" src="./img/90.png" alt="" style={{width:'80%'}}/>
                                                </div>
                                                <p style={{fontFamily:'Geomanist', color:'#e6007e', fontWeight:'bold'}} translate="no">Restrictivas</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="next"></div>
                            </div>
                        </div>
                    </div>
                    {/* paper 12 */}
                    <div id="page12" className="page">
                        {/* front */}
                        <div className="front">
                            <div className="front__content background-page22">
                                <div className="goBack"></div>
                                <div className="front__content-text" >
                                    <div style={{height:'20%', width:'100%', marginBottom:'10px'}}>
                                        <h4 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">NUESTROS</h4>
                                        <h6 style={{marginBottom:'5px', color:'#3d348a'}} translate="no">Clientes</h6>
                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingTop:'5px', color:'#3d348a'}}>
                                            <p style={{width:'50%', textAlign:'left'}} translate="no">
                                            </p>
                                        </div>
                                    </div>
                                    <div className="front__clientes">
                                        <div className="clientes">
                                            <img style={{maxWidth:"100%", height:'auto' }}  src="./img/45.png" alt="nuestros clientes" />
                                        </div>
                                        <div className="clientes">
                                            <img style={{maxWidth:"100%", height:'auto' }}  src="./img/46.png" alt="nuestros clientes" />
                                        </div>
                                        <div className="otros__clientes">
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}}  src="./img/47.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px' }} src="./img/48.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px' }}  src="./img/49.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px' }} src="./img/50.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}}  src="./img/51.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}} src="./img/52.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}}  src="./img/53.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}} src="./img/54.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}} src="./img/59.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}}  src="./img/60.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px'}} src="./img/57.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px' }}  src="./img/58.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"20%", height:'auto', margin:'3px 3px 3px 60px'}} src="./img/56.png" alt="nuestros clientes" />
                                            <img style={{maxWidth:"25%", height:'auto', margin:'3px 60px 3px 3px'}} src="./img/55.png" alt="nuestros clientes" />
                                        </div>
                                    </div>
                                </div>
                                <div className="next" onClick={(e) => this.flipPageNext('page5', 5)}></div>
                            </div>
                        </div>
                        {/* back */}
                        {/* <div className="back">
                            <div className="back__content background-page10">
                                <div className="goBack" onClick={(e) => this.flipPageBack('page5', 6)}></div>
                                <div className="front__content-text">
                                    10
                                </div>
                                <div className="next"></div>
                            </div>
                        </div> */}
                    </div>
                </div>              
            </div>      
        );
    }
}

export default DisplayDt;