import React, { Component } from "react";
import ObraCivilMV from './ObraCivilMV';

class ObraCivil  extends Component{

    constructor(props){
        super(props)
        this.state={      
            DisplayMV:false,
            DisplayDt:false,
            InitVw:true
        }
      }

    display(){

       /*  let userMac= navigator.platform.toUpperCase().indexOf('MAC') >=0;
        let userWin= navigator.platform.toUpperCase().indexOf('WIN') >=0;
        let evaUser = (userMac || userWin); */

        this.setState({
            ObraCivilMV:true,
            InitVw:false
        });
    }


    render(){
        return(
            <div id="page-flip" onLoad={(e) => this.display()}>
                {   
                    this.state.InitVw?
                    <div>
                        <img className="logo__init" width="200px" src="./img/logo.png" alt="logo" />
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    </div>
                    
                    :null  
                }

                {
                    this.state.ObraCivilMV?
                    <ObraCivilMV/>
                    :null
                }
            </div>      
        );
    }
}

export default ObraCivil;