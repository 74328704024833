import React, { Component } from 'react';
/* components */
import ControlList from './component/ControlList.jsx';
/* css */
import './App.css';
/* librerias */
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faHome, faShareAlt } from '@fortawesome/free-solid-svg-icons';
/* svg */
import Gamma from './svg/logo-gamma.svg';
import facebook from './svg/facebook.svg';
import instagram from './svg/instagram.svg';
import linkedin from './svg/linkedin.svg';
import youtube from './svg/youtube.svg';
import whatsapp from './svg/whatsapp.svg';
import email from './svg/email.svg';
import phone from './svg/phone.svg';

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      listVw: true,
    }
  }

  listVw(opcl) {
    sessionStorage.setItem('openClose', opcl);
    if (sessionStorage.getItem('openClose')) {
      sessionStorage.clear('openClose');
      this.setState({
        listVw: false,
      });
      setTimeout(() => (
        this.setState({
          listVw: true,
        })
      ), 400);
    }
    else {
      this.setState({
        listVw: true,
      });
    }
  }

  componentDidMount() {
    const elems = document.querySelectorAll('.fixed-action-btn');
    const instances = M.FloatingActionButton;
    instances.init(elems, {
      direction: 'top',
      hoverEnabled: false
    });
  }

  render() {
    return (
      <div className="App">
        {/* header */}
        <div className="App-header">
          <img src={Gamma} className="App-logo" alt="logo" />
        </div>
        {/* section */}
        <div className="App-cont">
          <div className="App-mg">
            {
              this.state.listVw ?
                <ControlList />
                : null
            }
          </div>
        </div>
        {/* footer */}
        <footer className="footer">
          <div className="footer__nav">
            {/* home */}
            <div className="App-btn" >
              <span className="App-lnk" style={{ cursor: 'pointer' }} onClick={(e) => this.listVw(true)}>
                <span className="">
                  <FontAwesomeIcon icon={faHome} />
                </span>
              </span>
            </div>
            {/* to share */}
            <div className="App-btn">
              <span className="App-lnk" style={{ cursor: 'pointer' }}>
                <div className="fixed-action-btn" style={{ position: 'relative', right: 0, bottom: 0, paddingTop: 0 }}>
                  <a className="" href="/#">
                    <span className="lnk" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faShareAlt} />
                    </span>
                  </a>
                  <ul>
                    <li>
                      <a className="btn-floating" style={{ backgroundColor: '#3b5998', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.facebook.com/Gammacg-106210921100777/" target="_blank" rel="noreferrer">
                        <img src={facebook} alt="facebook" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{ backgroundColor: '#125688', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.instagram.com/gammacg_/" target="_blank" rel="noreferrer">
                        <img src={instagram} alt="instagram" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{ backgroundColor: '#007bb5', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.linkedin.com/in/gamma-comunicaci%C3%B3n-gr%C3%A1fica-18b143217" target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="linkedin" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating" style={{ backgroundColor: '#bb0000', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.youtube.com/channel/UCtFFLBqoomHBHOkyEPkhENA" target="_blank" rel="noreferrer">
                        <img src={youtube} alt="linkedin" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
            {/* contacts */}
            <div className="App-btn">
              <span className="App-lnk" style={{ cursor: 'pointer' }}>
                <div className="fixed-action-btn" style={{ position: 'relative', right: 0, bottom: 0, paddingTop: 0 }}>
                  <a className="" href="/#">
                    <span className="lnk" style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faAddressBook} />
                    </span>
                  </a>
                  <ul>
                    <li>
                      <a className="btn-floating" style={{ backgroundColor: '#4dc247', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://api.whatsapp.com/send?phone=573226569777&text=%F0%9F%98%80%20Buen%20d%C3%ADa%2C%20estoy%20interesado%20en%20m%C3%A1s%20informaci%C3%B3n">
                        <img src={whatsapp} alt="whatsapp" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating red" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="mailto:gerencia@gammacg.com​">
                        <img src={email} alt="email" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                    <li>
                      <a className="btn-floating blue" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="tel:+573146183504​">
                        <img src={phone} alt="phone" style={{ width: '30px', height: '30px' }}></img>
                      </a>
                    </li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;