import React, { Component } from "react";
import Contents from "./Contents";
import ObraCivil from "./ObraCivil";
import Lista from "./Lista";
import Souvenirs from "./Souvenirs";

class ControlList  extends Component{

    constructor(){
        super()
        this.state={
          listaEBooksVw:true,
          contentsVw:false,
          souvenirsVw:false,
        }
    }
    
    abrirBrochure(){
        this.setState({
            listaEBooksVw:false,
            contentsVw:true,
            souvenirsVw:false,
        })
    }

    abrirObraCivil(){
        this.setState({
            listaEBooksVw:false,
            obraCivilVw:true,
            souvenirsVw:false,
        })
    }

    abrirSouvenirs(){
        this.setState({
            listaEBooksVw:false,
            obraCivilVw:false,
            souvenirsVw:true,
        })
    }

    render(){
        return(
            <div className="App-cont">
                <div className="App-book App-mg">
                    {
                        this.state.listaEBooksVw?
                        <div className="Cursos">
                            <div className="Lista-cursos">
                                <span onClick={() => this.abrirBrochure()}><Lista courses="Brochure" imgurl="./img/cover_page/book1.png" imgalt="Brochure Junio 2021"/></span>
                                <span onClick={() => this.abrirObraCivil()}><Lista courses="Obra Civil" imgurl="./img/cover_page/book2.png" imgalt="Obra Civil"/></span>
                                <span onClick={() => this.abrirSouvenirs()}><Lista courses="Souvenirs" imgurl="./img/cover_page/book3.png" imgalt="Souvenirs Perú"/></span>
                            </div>
                        </div>
                        :null
                    }

                    {
                        this.state.contentsVw?
                        <Contents/>
                        :null
                    }

                    {
                        this.state.obraCivilVw?
                        <ObraCivil/>
                        :null
                    }

                    {
                        this.state.souvenirsVw?
                        <Souvenirs/>
                        :null
                    }

                </div>
            </div>           
        );
    }
}

export default ControlList;